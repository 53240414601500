import React, { useState, useEffect } from "react";
import { Flex, Stack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import UsersAPI from "../../../APIs/UsersAPI";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import TimeOffRequestObj from "../../TimeOffRequests/classes/TimeOffRequestLegacy";
import { portalUser } from "../../../App";
import TimeOffCalendar from "../../TimeOffRequests/components/TimeOffCalendar";
import EmployeeProfileHeader from "../components/EmployeeProfileHeader";
import TimecardSummaryCard from "../../TimeOffRequests/components/TimecardSummaryCard";
import TimeOffRequestsCard from "../../TimeOffRequests/components/TimeOffRequestsCard";
import AvailableTimeOffCard from "../../TimeOffRequests/components/AvailableTimeOffCard";
// import { log } from "../../../helperFunctions";

export default function TimeOffRequestsPage({ tab, routeUID }) {
	const [upcomingRequests, setUpcomingRequests] = useState(null);
	const [requestUID, setRequestUID] = useState(null);

	useEffect(() => {
		const init = async () => {
			let tempUpcomingRequests = [];

			let today = new Date();
			let earliestStartDate = new Date();
			let latestEndDate = new Date();
			const usersAPI = new UsersAPI();
			let requestResult = await usersAPI.GetUserTimeoffRequests(portalUser.user.employeeUID);

			if (requestResult.status === 200) {
				for (let i = 0; i < requestResult?.value.length; i++) {
					let request = new TimeOffRequestObj(requestResult.value[i]);

					let start = new Date(request.startDate);
					let end = new Date(request.endDate);
					if (
						start.getTime() < earliestStartDate.getTime() &&
						request.status === 1 &&
						request.employeeUID === portalUser.user.employeeUID &&
						request.type === "Vacation"
					) {
						earliestStartDate = new Date(start);
					}
					if (
						end.getTime() > latestEndDate.getTime() &&
						request.status === 1 &&
						request.employeeUID === portalUser.user.employeeUID &&
						request.type === "Vacation"
					) {
						latestEndDate = new Date(end);
					}
					if (
						end.getTime() >= today.getTime() &&
						request.status === 1 &&
						request.employeeUID === portalUser.user.employeeUID &&
						request.type === "Vacation"
					) {
						tempUpcomingRequests.push(request);
					}
				}
				setUpcomingRequests(tempUpcomingRequests);
			}
			// setRequestUID(portalUser.tempUID);
			setRequestUID(routeUID);
		};
		init();
	}, [routeUID]);

	const clearRequestUID = () => {
		portalUser.tempUID = null;
		setRequestUID(null);
	};

	return (
		<Stack
			spacing={portalUser.styles?.pageSpacing ?? 8}
			p={portalUser.styles?.pageSpacing ?? 8}
			bg={portalUser.styles?.pageBG}
			w="full"
			flex={1}
			align="center"
		>
			<EmployeeProfileHeader />
			<Stack
				spacing={portalUser.styles?.pageSpacing ?? 8}
				flex={1}
				w="full"
				maxW="full"
				direction={{ base: "column", lg: "row" }}
			>
				<Stack spacing={portalUser.styles?.pageSpacing ?? 8} flex={1}>
					<motion.div whileHover={{ scale: 1.01, transition: { duration: 0.5 } }}>
						<TimecardSummaryCard />
					</motion.div>
					<motion.div whileHover={{ scale: 1.01, transition: { duration: 0.5 } }}>
						<AvailableTimeOffCard upcomingRequests={upcomingRequests} />
					</motion.div>
					<motion.div whileHover={{ scale: 1.01, transition: { duration: 0.5 } }}>
						<TimeOffRequestsCard
							routeUID={routeUID}
							requestUID={requestUID}
							clearRequestUID={clearRequestUID}
							summary
						/>
					</motion.div>
				</Stack>

				<Flex flex={3} direction="column" w="full" maxW="full" overflow="auto" pb={4}>
					<BasicCard title="Time Off Calendar" portalStyles={portalUser.styles}>
						<TimeOffCalendar />
					</BasicCard>
				</Flex>
			</Stack>
		</Stack>
	);
}
