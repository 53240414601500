import { portalUser } from "../../../App";
import UsersAPI from "../../../APIs/UsersAPI";
import Email from "../../Employee/classes/Email";
import Notification from "../../Employee/classes/Notification";

import { PPMUser } from "../../../PPM_SDK/PPMUser";
import { v4 } from "uuid";
import { dayDifference, formatDate, log } from "../../../helperFunctions";

export default class TimeOffRequestLegacy {
	constructor(request) {
		this.usersAPI = new UsersAPI();
		this.keyId = request?.KeyID ?? request?.keyId ?? null;
		this.id = request?.RequestUID ?? request?.id ?? v4();
		this.employeeUID =
			request?.EmployeeUID?.toLowerCase() ??
			request?.employeeUID?.toLowerCase() ??
			portalUser.user?.employeeUID?.toLowerCase();
		this.approverUID = request?.ApproverUID?.toLowerCase() ?? null;
		this.sentTo = request?.SentTo?.toLowerCase() ?? request?.sentTo?.toLowerCase() ?? null;
		this.sentToName = request?.SentToName ?? request?.sentToName ?? "";
		this.dateCreated = request?.DateCreated ?? request?.dateCreated ?? new Date().toLocaleDateString();
		this.type = request?.Type ?? request?.type ?? "Vacation";
		this.startDate = formatDate(request?.StartDate) ?? request?.startDate ?? null;
		this.endDate = request?.EndDate?.replace(/-/gm, "/")?.replace(/T.*/gm, " 17:00:00") ?? request?.endDate ?? null;
		this.requestedHours = request?.RequestedHours ?? request?.requestedHours ?? 0;
		this.status = request?.Status ?? request?.status ?? -1;
		this.dateAcknowledged = request?.DateAcknowledged ?? request?.dateAcknowledged ?? null;
		this.dateProcessed = request?.DateProcessed ?? request?.dateProcessed ?? null;
		this.acknowledgedBy = request?.AcknowledgedBy?.toLowerCase() ?? request?.acknowledgedBy?.toLowerCase() ?? null;
		this.note = request?.Note ?? request?.note ?? null;
		this.requestNote = request?.RequestNote ?? request?.requestNote ?? null;
		this.secondarySentTo = request?.SecondarySentTo ?? request?.secondarySentTo ?? null;
		this.secondaryAcknowledgedBy = request?.SecondaryAcknowledgedBy ?? request?.secondaryAcknowledgedBy ?? null;
		this.secondaryDateAcknowledged =
			request?.SecondaryDateAcknowledged ?? request?.secondaryDateAcknowledged ?? null;
		this.notifyUsers = request?.notifyUsers ?? []; // Not in DB
		this.name = request?.FirstName + " " + request?.LastName ?? "";
	}

	async upload() {
		if (Boolean(this.type) && Boolean(this.sentTo)) {
			if (this.notifyUsers?.length > 0) {
				let notifyIds = [];
				for (let i = 0; i < this.notifyUsers?.length; i++) {
					notifyIds.push(this.notifyUsers[i].employeeUID);
				}

				this.secondarySentTo = notifyIds.toString();
			}
			let uploadResult = await this.usersAPI.InsertTimeoffRequest(this);

			if (uploadResult.status === 200) {
				let notification = new Notification();
				notification.employeeUID = this.sentTo;
				notification.type = "Notification";
				notification.category = "timeoff";
				notification.subject = `Time Off Request`;
				notification.body = portalUser.user?.name + " has submitted a " + this.type + " request.";
				notification.referenceUID = this.id;
				notification.link = "/employee/timeoff";
				let result = this.insertNotificationSQL(notification);
				if (Boolean(result)) {
					let email = new Email();
					await email.addRecipientById(this.sentTo);
					email.subject = "Time Off Request";
					email.body = (
						<p>
							{portalUser.user?.name} has requested time off from{" "}
							{new Date(this.startDate).toLocaleDateString()} to{" "}
							{new Date(this.endDate).toLocaleDateString()}
							<br />
							Click <a href="https://ppmportal.com/employee/timeoff">here</a> to view.
						</p>
					);
					email.send();

					if (this.notifyUsers?.length > 0) {
						email.body = (
							<p>
								{portalUser.user?.name} has requested time off from{" "}
								{new Date(this.startDate).toLocaleDateString()} to{" "}
								{new Date(this.endDate).toLocaleDateString()}
								<br />
								This is only a notification, no further action is required.
							</p>
						);
						for (let i = 0; i < this.notifyUsers?.length; i++) {
							let secondaryNotification = new NotificationLegacy(notification);
							secondaryNotification.id = v4();
							secondaryNotification.employeeUID = this.notifyUsers[i]?.employeeUID;
							secondaryNotification.type = "Notification";

							let secondaryResult = this.insertNotificationSQL(secondaryNotification);
							if (Boolean(secondaryResult)) {
								let secondaryEmail = new Email(email);
								await secondaryEmail.addRecipientById(this.notifyUsers[i]?.employeeUID);
								secondaryEmail.send();
							}
						}
					}

					return true;
				}
			}
		}
	}

	async delete() {
		let result = await this.usersAPI.DeleteTimeOffRequest(this.id);
		if (result.status === 200) {
			return true;
		} else {
			return false;
		}
	}

	async updateDB(updates) {
		let result = await this.usersAPI.UpdateTimeOffRequest(this.id, updates);
		if (result.status === 200) {
			return true;
		} else {
			return false;
		}
	}

	async approve() {
		let dateAcknowledged = new Date().toLocaleDateString();
		let acknowledgedBy = portalUser.user?.employeeUID?.toLowerCase();

		let update = {};

		if (acknowledgedBy === this.sentTo) {
			this.dateAcknowledged = dateAcknowledged;
			this.acknowledgedBy = acknowledgedBy;
			this.status = 1;

			update = {
				DateAcknowledged: this.dateAcknowledged,
				AcknowledgedBy: this.acknowledgedBy,
				Status: this.status,
			};
		}

		this.notifyHR();
		let syncStatus = await this.updateDB(update);
		let timeoffEmployee = await this.usersAPI.GetUserByEmployeeUID(this.employeeUID);
		if (timeoffEmployee.value?.length > 0) {
			timeoffEmployee = new PPMUser(timeoffEmployee.value[0]);
		}
		let msg =
			"The time off request for " +
			timeoffEmployee.name +
			" has been approved for " +
			new Date(this.startDate).toLocaleDateString() +
			" to " +
			new Date(this.endDate).toLocaleDateString() +
			".";
		await this.notifyEmployee();
		await this.notifySecondary(msg);

		log("syncStatus", syncStatus);

		return syncStatus;
	}

	async decline() {
		let dateAcknowledged = new Date().toLocaleDateString();
		let acknowledgedBy = portalUser.user?.employeeUID?.toLowerCase();
		let update = {};

		if (acknowledgedBy === this.sentTo) {
			this.dateAcknowledged = dateAcknowledged;
			this.acknowledgedBy = acknowledgedBy;
			this.status = 0;

			update = {
				DateAcknowledged: this.dateAcknowledged,
				AcknowledgedBy: this.acknowledgedBy,
				Status: this.status,
			};
		}
		let syncStatus = await this.updateDB(update);

		let timeoffEmployee = await this.usersAPI.GetUserByEmployeeUID(this.employeeUID);
		if (timeoffEmployee.value?.length > 0) {
			timeoffEmployee = new PPMUser(timeoffEmployee.value[0]);
		}
		let msg =
			"The time off request for " +
			timeoffEmployee.name +
			" has been denied for " +
			new Date(this.startDate).toLocaleDateString() +
			" to " +
			new Date(this.endDate).toLocaleDateString() +
			".";
		await this.notifyEmployee();
		await this.notifySecondary(msg);

		return syncStatus;
	}

	async cancel() {
		this.status = 2;

		let update = {
			Status: this.status,
		};
		let syncStatus = await this.updateDB(update);

		let notification = new Notification();
		notification.employeeUID = this.sentTo;
		notification.type = "Notification";
		notification.category = "timeoff";
		notification.subject = `Time Off Request Cancelled`;
		notification.body =
			portalUser.user?.name +
			" has cancelled their " +
			this.type +
			" request from " +
			new Date(this.startDate).toLocaleDateString() +
			" to " +
			new Date(this.endDate).toLocaleDateString() +
			".";
		notification.referenceUID = this.id;

		let result = this.insertNotificationSQL(notification);

		if (Boolean(result)) {
			let email = new Email();
			await email.addRecipientById(this.sentTo);
			email.subject = "Time Off Request";
			email.body = (
				<p>
					{portalUser.user?.name} has cancelled their {this.type} request from{" "}
					{new Date(this.startDate).toLocaleDateString()} to {new Date(this.endDate).toLocaleDateString()}.
					<br />
					{/* Click <a href="https://ppmportal.com">here</a> to view. */}
					This is only a notification, no further action is required.
				</p>
			);

			email.send();
		}

		let msg =
			portalUser.user?.name +
			" has cancelled their time off request for " +
			new Date(this.startDate).toLocaleDateString() +
			" to " +
			new Date(this.endDate).toLocaleDateString() +
			".";
		await this.notifySecondary(msg);

		return syncStatus;
	}

	async reset() {
		this.status = -1;
		let update = {
			Type: this.type,
			StartDate: this.startDate,
			EndDate: this.endDate,
			RequestNote: this.requestNote,
			Status: this.status,
		};
		await this.updateDB(update);
		let msg = portalUser.user?.name + " has updated their time off request and requires a response.";
		await this.notifySentTo(msg, "Request");
	}

	async notifySentTo(msg, notificationType) {
		let notification = new Notification();
		notification.employeeUID = this.sentTo;
		notification.type = notificationType ?? "Notification";
		notification.category = "timeoff";
		notification.subject = `Time Off Request`;
		notification.body = msg;
		notification.referenceUID = this.id;
		notification.link = "/employee/timeoff";

		let result = this.insertNotificationSQL(notification);
		if (Boolean(result)) {
			let email = new Email();
			await email.addRecipientById(this.sentTo);
			email.subject = "Time Off Request";
			email.body = (
				<p>
					{msg}
					<br />
					Click <a href="https://ppmportal.com/employee/timeoff">here</a> to view.
					{/* This is only a notification, no further action is required. */}
				</p>
			);
			email.send();
		}
	}

	async notifyHR() {
		let timeoffEmployee = await this.usersAPI.GetUserByEmployeeUID(this.employeeUID);
		if (timeoffEmployee.value?.length > 0) {
			timeoffEmployee = new PPMUser(timeoffEmployee.value[0]);
		}
		let email = new Email();
		email.addTo("michellec@ppmechanical.com");
		email.addTo("carolineb@ppmechanical.com");
		email.subject = "Time Off Request";
		email.body = (
			<p>
				{timeoffEmployee?.name} has been approved for a {this.type} request from{" "}
				{new Date(this.startDate).toLocaleDateString()} to {new Date(this.endDate).toLocaleDateString()}.
			</p>
		);
		email.send();
	}

	async notifyEmployee() {
		let notification = new Notification();
		notification.employeeUID = this.employeeUID;
		notification.type = "Notification";
		notification.category = "timeoff";
		notification.subject = `Time Off Request`;
		notification.body =
			"Your " +
			this.type +
			" request for " +
			new Date(this.startDate).toLocaleDateString() +
			" to " +
			new Date(this.endDate).toLocaleDateString() +
			" has been " +
			(this.status === 0 ? "declined" : "approved") +
			".";
		notification.referenceUID = this.id;
		notification.link = "/employee/timeoff";

		let result = this.insertNotificationSQL(notification);
		if (Boolean(result)) {
			let email = new Email();
			await email.addRecipientById(this.employeeUID);
			email.subject = "Time Off Request " + (this.status === 0 ? " Declined" : " Approved");
			email.body = (
				<p>
					Your {this.type} request for {new Date(this.startDate).toLocaleDateString()} to{" "}
					{new Date(this.endDate).toLocaleDateString()} has been
					{this.status === 0 ? " declined" : " approved"}.
				</p>
			);
			email.send();

			// if (this.notifyUsers?.length > 0) {
			// 	for (let i = 0; i < this.notifyUsers?.length; i++) {
			// 		let secondaryNotification = new Notification(notification);
			// 		secondaryNotification.id = v4();
			// 		secondaryNotification.employeeUID = this.notifyUsers[i].employeeUID;
			// 		secondaryNotification.type = "Notification";

			// 		let secondaryResult = this.insertNotificationSQL(secondaryNotification);
			// 		if (Boolean(secondaryResult)) {
			// 			let secondaryEmail = new Email(email);
			// 			await secondaryEmail.addRecipientById(this.notifyUsers[i].employeeUID);
			// 			secondaryEmail.send();
			// 		}
			// 	}
			// }

			// return true;
		}
	}

	async notifySecondary(msg) {
		if (this.secondarySentTo) {
			let notifyIds = this.secondarySentTo;
			notifyIds = notifyIds.split(",");

			for (let i = 0; i < notifyIds.length; i++) {
				let notification = new Notification();
				notification.employeeUID = notifyIds[i];
				notification.type = "Notification";
				notification.category = "timeoff";
				notification.subject = `Time Off Request`;
				notification.body = msg;
				notification.referenceUID = this.id;
				// notification.link = "/employee/timeoff";
				let result = this.insertNotificationSQL(notification);
				if (Boolean(result)) {
					let email = new Email();
					await email.addRecipientById(notifyIds[i]);
					email.subject = "Time Off Request";
					email.body = (
						<p>
							{msg}
							<br />
							{/* Click <a href="https://ppmportal.com/employee/timeoff">here</a> to view. */}
							This is only a notification, no further action is required.
						</p>
					);
					email.send();
				}
			}
		}
	}

	async updateNote(note) {
		note = note.replace(/'/gm, "`");
		this.note = note;
		let update = {
			Note: note,
		};
		let syncStatus = await this.updateDB(update);
		return syncStatus;
	}

	getStatusInfo() {
		if (this.status === 0) {
			return { status: "denied", color: "red.500", icon: "fas fa-ban", colorScheme: "red" };
		} else if (this.status === 1) {
			return { status: "approved", color: "green.500", icon: "fas fa-check-circle", colorScheme: "green" };
		} else if (this.status === 2) {
			return { status: "cancelled", color: "red.500", icon: "fas fa-ban", colorScheme: "red" };
		} else if (this.status === -1) {
			return { status: "pending", color: "yellow.500", icon: "fas fa-question-circle", colorScheme: "yellow" };
		} else {
			return { status: "cancelled", color: "red.400", icon: "fas fa-ban", colorScheme: "red" };
		}
	}

	getDays() {
		return dayDifference(this.startDate, this.endDate);
	}

	async insertNotificationSQL(notification) {
		log("Notification INSERT SQL", notification);
		let insertResult = await this.usersAPI.InsertNotification(notification);
		log("Notification INSERT RESULT SQL", notification);
		if (insertResult.status === 200) {
			return true;
		} else {
			return false;
		}
	}
}

export class NotificationLegacy {
	constructor(notification) {
		this.usersAPI = new UsersAPI();
		this.keyId = notification?.keyID ?? notification?.KeyID ?? notification?.keyId ?? null;
		this.id = notification?.NotificationUID ?? notification?.id ?? v4();
		this.employeeUID =
			notification?.employeeUID?.toLowerCase() ??
			notification?.EmployeeUID?.toLowerCase() ??
			notification?.employeeUID?.toLowerCase() ??
			null;
		this.approverUID = notification?.approverUID?.toLowerCase() ?? notification?.ApproverUID?.toLowerCase() ?? null;
		this.referenceUID =
			notification?.referenceUID ?? notification?.ReferenceUID ?? notification?.referenceUID ?? null;
		this.type = notification?.type ?? notification?.Type ?? null;
		this.subject = notification?.subject ?? notification?.Subject ?? "";
		this.body = notification?.body ?? notification?.Body ?? "";
		this.category = notification?.category ?? notification?.Category ?? null;
		this.link = notification?.link ?? notification?.Link ?? null;
		this.dateCreated = notification?.DateCreated ?? notification?.dateCreated ?? new Date().toLocaleDateString();
		this.createdBy =
			notification?.CreatedBy?.toLowerCase() ??
			notification?.createdBy?.toLowerCase() ??
			portalUser.user.employeeUID?.toLowerCase();
		this.dateAcknowledged = notification?.DateAcknowledged ?? notification?.dateAcknowledged ?? null;
		this.dateExpired = notification?.DateExpired ?? notification?.dateExpired ?? null;
	}

	async upload() {
		let insertResult = await this.usersAPI.InsertNotification(this);
		return insertResult;
	}

	async updateDB(updates) {
		let result = await this.usersAPI.UpdateNotification(this.id, updates);
		if (result.status === 200) {
			return true;
		} else {
			return false;
		}
	}

	async dismiss() {
		let date = new Date();
		this.dateAcknowledged = date;
		let updates = {
			DateAcknowledged: date.toLocaleDateString(),
		};

		return await this.updateDB(updates);
	}

	show() {
		if (this.dateAcknowledged === null) {
			return true;
		}
		return false;
	}

	async getRequest() {
		let request = {};
		if (this.category === "timeoff") {
			let requestResult = await this.usersAPI.GetTimeOffRequest(this.referenceUID);
			if (requestResult.status === 200 && requestResult.value.length > 0) {
				request.employee = new PPMUser(requestResult.value[0]);
				request.request = new TimeOffRequestLegacy(requestResult.value[0]);
			}
		}

		return request;
	}
}
