import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@chakra-ui/react";
import UsersAPI from "../../../APIs/UsersAPI";
import { PPMUser } from "../../../PPM_SDK/PPMUser";
import TimeOffRequestLegacy from "../classes/TimeOffRequestLegacy";
import TimeOffAcknowledgeModal from "./TimeOffAcknowledgeModal";
// import { log } from "../../../helperFunctions";

export default function TimeOffRequest(props) {
	const usersAPI = new UsersAPI();
	const [requestId, setRequestId] = useState(null);
	const [request, setRequest] = useState(null);
	const [employee, setEmployee] = useState(null);
	// const [user, setUser] = useState(null);

	const init = useCallback(async () => {
		if (props.requestId) {
			let requestResult = await usersAPI.GetTimeOffRequest(props.requestId);
			if (requestResult.status === 200 && requestResult.value.length > 0) {
				let tempEmployee = new PPMUser(requestResult.value[0]);
				let tempRequest = new TimeOffRequestLegacy(requestResult.value[0]);
				setRequestId(props.requestId);
				setRequest(tempRequest);
				setEmployee(tempEmployee);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.requestId]);

	useEffect(() => {
		init();
	}, [init]);

	useEffect(() => {
		if (props.requestId !== requestId) {
			setRequestId(props.requestId);
			setTimeout(init, 200);
		}

		if (props.request && props.request?.id !== request?.id && props.isOpen && props.view === "request") {
			setRequest(props.request?.request);
			// setUser(props.request?.employee);
		}
	}, [init, props.isOpen, props.request, props.requestId, props.view, request?.id, requestId]);

	const approve = async () => {
		let tempRequest = request;
		// log("approve request", request);
		await tempRequest.approve();
		setRequest(tempRequest);
		props.onClose();
	};

	const decline = async () => {
		let tempRequest = request;
		// log("decline request", request);
		await tempRequest.decline();
		setRequest(tempRequest);
		props.onClose();
	};

	const cancel = async () => {
		let tempRequest = request;
		// log("cancel request", request);
		await tempRequest.delete();
		props.cancelRequest(tempRequest?.id);
		setRequest(tempRequest);
		props.onClose();
	};

	const updateNote = async (ev) => {
		let note = ev.target.value;
		let tempRequest = request;
		await tempRequest.updateNote(note);
		setRequest(tempRequest);
	};

	const updateRequest = async (changes) => {
		let tempRequest = request;
		let resetNeeded = false;
		if (changes.startDate) {
			if (changes?.startDate.toLocaleDateString() !== new Date(request?.startDate).toLocaleDateString()) {
				request.startDate = changes.startDate.toLocaleDateString();
				resetNeeded = true;
			}
			if (changes.endDate.toLocaleDateString() !== new Date(request?.endDate).toLocaleDateString()) {
				request.endDate = changes.endDate.toLocaleDateString();
				resetNeeded = true;
			}
			if (changes.requestNote !== request?.requestNote) {
				request.requestNote = changes.requestNote;
				resetNeeded = true;
			}
			if (changes?.type !== request?.type) {
				request.type = changes?.type;
				resetNeeded = true;
			}
		}
		if (resetNeeded) {
			await tempRequest.reset();
		}
		setRequest(tempRequest);
		if (props.updateRequest) {
			props.updateRequest(tempRequest);
		}
		props.onClose();
	};

	return props.view === "modal" ? (
		props.isOpen && request ? (
			<TimeOffAcknowledgeModal
				isOpen={props.isOpen}
				onClose={props.onClose}
				request={request}
				employee={employee}
				decline={decline}
				approve={approve}
				updateNote={updateNote}
				cancel={cancel}
				updateRequest={updateRequest}
				holidays={props.holidays}
			/>
		) : null
	) : (
		<Box></Box>
	);
}
