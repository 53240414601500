import React, { useMemo } from "react";
import { Switch, Route, useLocation, useParams, Redirect } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import TeamMembersPage from "../pages/TeamMembersPage";
import HRReviewsPage from "../../HRAdmin/pages/HRReviewsPage";

export default function TeamRoutes({ tab }) {
	let location = useLocation();

	return (
		<Switch location={location}>
			<Route exact path={`/team`} children={<TeamRouteViews tab={tab} />} />
			<Route exact path={`/team/:tab`} children={<TeamRouteViews tab={tab} />} />
			<Route path={`/team/:tab/:formUID`} children={<TeamRouteViews tab={tab} />} />
		</Switch>
	);
}

function TeamRouteViews(props) {
	let params = useParams();

	const prepareTab = (tab = null) => {
		if (!["members", "reviews"]?.includes(tab)) {
			return null;
		} else {
			return tab;
		}
	};

	const prepareUID = (uid = null) => {
		if (!Boolean(uid?.length === 36)) {
			return null;
		} else {
			return uid?.toLowerCase();
		}
	};

	var routeTab = prepareTab(params?.tab);
	var routeUID = prepareUID(params?.routeUID);

	const tab = useMemo(() => {
		return prepareTab(props.tab);
	}, [props.tab]);

	return (
		<Flex flex={1} w="full" direction="row" justify="center">
			<Flex direction={"column"} w="full" flex={1}>
				{Boolean(tab) && tab !== routeTab ? (
					<Redirect push to={`/team/${tab}`} />
				) : ["members"].includes(routeTab) && Boolean(routeUID) ? (
					<Redirect push to={`/team/${routeTab}`} />
				) : ["members"].includes(routeTab) ? (
					<TeamMembersPage tab={routeTab} />
				) : ["reviews"].includes(routeTab) ? (
					<HRReviewsPage tab={routeTab} routeUID={routeUID} />
				) : (
					<Box>Something went wrong - there is an issue with the Tab or FormUID</Box>
				)}
			</Flex>
		</Flex>
	);
}
