import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
	Box,
	Center,
	Flex,
	Stack,
	Heading,
	Button,
	HStack,
	Text,
	Divider,
	VStack,
	StackDivider,
	Skeleton,
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	Collapse,
	Circle,
	IconButton,
	SimpleGrid,
} from "@chakra-ui/react";
import { FaUser, FaUsers } from "react-icons/fa";

import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { VscArrowSwap, VscArrowRight } from "react-icons/vsc";
import { IoOptionsOutline } from "react-icons/io5";

import { ppmUser, portalUser } from "../../../App";
import BasicCard from "../../../core/Layout/cards/BasicCard";
import UsersAPI from "../../../APIs/UsersAPI";
import { PPMUser } from "../../../PPM_SDK/PPMUser";
import TimeOffRequestLegacy from "../classes/TimeOffRequestLegacy";
import TimeOffRequest from "./TimeOffRequest";
import TimeOffRequestModal from "./TimeOffRequestModal";
import TimeOffRequestTileRow from "./TimeOffRequestListItem";
import { mergeSort, log } from "../../../helperFunctions";

export default function TimeOffRequestsCard({ routeUID, requestUID, clearRequestUID }) {
	const history = useHistory();
	const usersAPI = new UsersAPI();
	const [allRequests, setAllRequests] = useState(null);
	const [upcomingRequests, setUpcomingRequests] = useState(null);
	const [pendingRequests, setPendingRequests] = useState(null);
	const [pendingCount, setPendingCount] = useState(null);
	const [requests, setRequests] = useState(null);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [requestId, setRequestId] = useState(routeUID ?? requestUID ?? null);
	const [requestModalIsOpen, setRequestModalIsOpen] = useState(false);
	const [managerUID, setManagerUID] = useState(null);
	const [managers, setManagers] = useState(null);
	const [users, setUsers] = useState(null);
	const [viewOption, setViewOption] = useState("employee");
	const [viewAll, setViewAll] = useState(false);
	const [tabVal, setTabVal] = useState(0);
	const [showMore, setShowMore] = useState(false);
	const [holidays, setHolidays] = useState([]);

	const init = useCallback(async () => {
		let tempManagerUID = portalUser.user.approverUID?.toLowerCase();
		let tempAllRequests = [];
		let tempUpcomingRequests = [];
		let tempPendingRequests = [];
		let tempPendingCount = 0;

		let today = new Date();
		let earliestStartDate = new Date();
		let latestEndDate = new Date();

		let requestResult = await usersAPI.GetUserTimeoffRequests(portalUser?.user?.employeeUID);
		if (requestResult.status === 200) {
			for (let i = 0; i < requestResult?.value?.length; i++) {
				let request = {
					request: new TimeOffRequestLegacy(requestResult.value[i]),
				};
				tempAllRequests.push(request);

				let start = new Date(request?.request?.startDate);
				let end = new Date(request?.request?.endDate);
				if (start.getTime() < earliestStartDate.getTime()) {
					earliestStartDate = new Date(start);
				}
				if (end.getTime() > latestEndDate.getTime()) {
					latestEndDate = new Date(end);
				}
				if (end.getTime() >= today.getTime()) {
					tempUpcomingRequests.push(request);
				}

				if (
					request?.request?.status === -1 &&
					request?.request?.approverUID?.toLowerCase() === portalUser?.user?.employeeUID?.toLowerCase()
				) {
					tempPendingRequests.push(request);
					tempPendingCount++;
				}
			}

			setManagerUID(tempManagerUID?.toLowerCase());
			setAllRequests(tempAllRequests);
			setUpcomingRequests(tempUpcomingRequests);
			setPendingRequests(tempPendingRequests);
			setPendingCount(tempPendingCount);
		}

		let params = {};
		let query = {
			eventType: "Holiday",
			startDate: earliestStartDate?.toLocaleDateString(),
			endDate: latestEndDate?.toLocaleDateString(),
		};
		let tempHolidays = await usersAPI.GetCalendarEvents(params, query);

		if (tempHolidays.status === 200) {
			tempHolidays = tempHolidays.value;
		}

		let activeRequests = [];
		let tempRequests = [];
		let tempViewOption = viewOption;
		let tempViewAll = viewAll;

		if (tempViewAll) {
			activeRequests = tempAllRequests;
		} else {
			activeRequests = tempUpcomingRequests;
		}

		for (let i = 0; i < activeRequests?.length; i++) {
			if (
				tempViewOption === "employee" &&
				activeRequests[i].request?.employeeUID?.toLowerCase() === portalUser?.user?.employeeUID?.toLowerCase()
			) {
				tempRequests.push(activeRequests[i]);
			}
			if (
				tempViewOption === "approver" &&
				(activeRequests[i].request.approverUID?.toLowerCase() ===
					portalUser?.user?.employeeUID?.toLowerCase() ||
					activeRequests[i].request.employeeUID?.toLowerCase() ===
						portalUser?.user?.employeeUID?.toLowerCase())
			) {
				tempRequests.push(activeRequests[i]);
			}
			if (tempViewOption === "department" && activeRequests[i].request.status === 1) {
				tempRequests.push(activeRequests[i]);
			}
		}

		setHolidays(tempHolidays);
		setRequests(tempRequests);

		if (Boolean(routeUID)) {
			setModalIsOpen(Boolean(routeUID));
		} else if (Boolean(requestUID)) {
			let request = activeRequests?.find((d) => d.request.id?.toLowerCase() === requestUID);
			setRequestId(request?.request?.id ?? null);
			// setModalIsOpen(Boolean(request?.request?.id)),
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allRequests, requestUID, routeUID, upcomingRequests, viewAll, viewOption]);

	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const viewAllFunction = async () => {
		let tempAllRequests = allRequests;
		let tempUpcomingRequests = upcomingRequests;
		let activeRequests = [];
		let tempRequests = [];
		let tempViewOption = viewOption;
		let tempViewAll = !viewAll;

		if (viewAll) {
			activeRequests = tempAllRequests;
		} else {
			activeRequests = tempUpcomingRequests;
		}

		for (let i = 0; i < activeRequests.length; i++) {
			if (
				tempViewOption === "employee" &&
				activeRequests[i].request.employeeUID?.toLowerCase() === portalUser?.user?.employeeUID?.toLowerCase()
			) {
				tempRequests.push(activeRequests[i]);
			}
			if (
				tempViewOption === "approver" &&
				(activeRequests[i].request.approverUID?.toLowerCase() ===
					portalUser?.user?.employeeUID?.toLowerCase() ||
					activeRequests[i].request.employeeUID?.toLowerCase() ===
						portalUser?.user?.employeeUID?.toLowerCase())
			) {
				tempRequests.push(activeRequests[i]);
			}
			if (tempViewOption === "department" && activeRequests[i].request.status === 1) {
				tempRequests.push(activeRequests[i]);
			}
		}
		setViewAll(tempViewAll);
		setRequests(tempRequests);
	};

	const createRequest = async (request) => {
		setIsSubmitting(true);
		let tempRequests = requests;
		request = new TimeOffRequestLegacy(request);
		let result = await request.upload();
		let tempManagerUID = managerUID?.toLowerCase();

		if (result) {
			if (request.sentTo?.toLowerCase() !== portalUser.user?.approverUID?.toLowerCase()) {
				await usersAPI.UpdateEmployeeManagement(request.employeeUID?.toLowerCase(), {
					ApproverUID: request.sentTo?.toLowerCase(),
				});
				tempManagerUID = request.sentTo?.toLowerCase();
			}
		}

		// let tempPendingCount = pendingCount;
		if (result) {
			let newRequest = {
				request: request,
				employee: ppmUser,
			};
			tempRequests.push(newRequest);
			// pendingCount++;
		}
		setRequests(tempRequests);
		setIsSubmitting(false);
		setRequestModalIsOpen(false);
		setManagerUID(tempManagerUID.toLowerCase());
	};

	const openModal = (request) => {
		history.push(`/employee/timeoff/${request?.id}`);
		setModalIsOpen(true);
		setRequestId(request.id);
	};

	const closeModal = () => {
		clearRequestUID();
		history.push("/employee/timeoff");
		setModalIsOpen(false);
		setRequestId(null);
		init();
	};

	const openRequestModal = async () => {
		let tempManagers = await usersAPI.GetApprovers(portalUser?.user?.employeeUID);
		let tempUsers = await usersAPI.GetAllUsers("short");

		tempManagers = tempManagers.value;
		for (let i = 0; i < tempManagers?.length; i++) {
			tempManagers[i] = new PPMUser(tempManagers[i]);
		}
		tempManagers = mergeSort(tempManagers, { name: "firstName", type: "string" }, 0);

		tempUsers = tempUsers.value;
		let usersWithEmail = [];
		for (let i = 0; i < users?.length; i++) {
			users[i] = new PPMUser(users[i]);
			if (
				tempUsers[i].email !== "" &&
				tempUsers[i].employeeUID?.toLowerCase() !== portalUser?.user?.employeeUID?.toLowerCase()
			) {
				usersWithEmail.push(tempUsers[i]);
			}
		}
		usersWithEmail = mergeSort(usersWithEmail, { name: "firstName", type: "string" }, 0);

		const lowercaseManagers = tempManagers.map((obj) => ({
			...obj,
			employeeUID: obj["employeeUID"]?.toLowerCase(),
		}));

		setRequestModalIsOpen(true);
		setManagers(lowercaseManagers);
		setUsers(usersWithEmail);
	};

	const closeRequestModal = () => {
		setRequestModalIsOpen(false);
	};

	const toggleShowMore = () => {
		setShowMore(!showMore);
	};

	const setViewOptionFunction = (option) => {
		let tempAllRequests = allRequests;
		let tempUpcomingRequests = upcomingRequests;
		let activeRequests = [];
		let tempRequests = [];
		let tempViewOption = option;
		let tempViewAll = viewAll;

		if (tempViewAll) {
			activeRequests = tempAllRequests;
		} else {
			activeRequests = tempUpcomingRequests;
		}

		for (let i = 0; i < activeRequests?.length; i++) {
			if (
				tempViewOption === "employee" &&
				activeRequests[i].request.employeeUID?.toLowerCase() === portalUser?.user?.employeeUID?.toLowerCase()
			) {
				tempRequests.push(activeRequests[i]);
			}
			if (
				tempViewOption === "approver" &&
				(activeRequests[i].request.approverUID?.toLowerCase() ===
					portalUser?.user?.employeeUID?.toLowerCase() ||
					activeRequests[i].request.employeeUID?.toLowerCase() ===
						portalUser?.user?.employeeUID?.toLowerCase())
			) {
				tempRequests.push(activeRequests[i]);
			}
			if (tempViewOption === "department" && activeRequests[i].request.status === 1) {
				tempRequests.push(activeRequests[i]);
			}
		}

		setRequests(tempRequests);
		setViewOption(tempViewOption);
		// setViewOption(option); //should be setViewOption(tempViewOption)?
	};

	const updateRequest = (request) => {
		let tempRequests = requests;
		for (let i = 0; i < tempRequests?.length; i++) {
			if (tempRequests[i].request?.id === request?.id) {
				tempRequests[i].request.startDate = request.startDate;
				tempRequests[i].request.endDate = request.endDate;
				tempRequests[i].request.status = request.status;
				tempRequests[i].request.type = request.type;
				break;
			}
		}
		setRequests(tempRequests);
	};

	const cancelRequest = (selectedRequestUID) => {
		let tempRequests = requests;
		for (let i = 0; i < tempRequests?.length; i++) {
			if (tempRequests[i].request?.id === selectedRequestUID) {
				tempRequests.splice(i, 1);
				break;
			}
		}
		setRequests(tempRequests);
	};

	const handleTabChange = (val) => {
		setTabVal(val);
	};
	const applyFilters = () => {
		setTabVal(0);
	};

	const [tabTitle, setTabTitle] = useState("My Time Off");
	useEffect(() => {
		let tempTabTitle = tabTitle;
		let tempViewOption = viewOption;
		if (tempViewOption === "employee") {
			tempTabTitle = "My Time Off";
		} else if (tempViewOption === "approver") {
			tempTabTitle = "Team Time Off";
		} else if (tempViewOption === "department") {
			tempTabTitle = "My Department";
		}
		setTabTitle(tempTabTitle);
	}, [tabTitle, viewOption]);

	return (
		<BasicCard title="Time Off Requests" portalStyles={portalUser.styles}>
			{Boolean(requestId) && (
				<TimeOffRequest
					isOpen={modalIsOpen}
					onClose={closeModal}
					requestId={requestId}
					view="modal"
					updateRequest={updateRequest}
					holidays={holidays}
					cancelRequest={cancelRequest}
				/>
			)}
			{requestModalIsOpen && managers && (
				<TimeOffRequestModal
					isOpen={requestModalIsOpen}
					closeModal={closeRequestModal}
					createRequest={createRequest}
					submitting={isSubmitting}
					managers={managers}
					managerUID={managerUID}
					users={users}
				/>
			)}

			<Box>
				<Skeleton isLoaded={Boolean(requests)} rounded="5">
					<Flex w="full" mt="2" rounded="5">
						<Tabs
							isFitted
							w="full"
							colorScheme="teal"
							color="gray.500"
							index={tabVal}
							onChange={handleTabChange}
						>
							<TabList>
								<Tab isTruncated textTransform="uppercase" fontWeight="semibold">
									<Text letterSpacing={1}>{tabTitle}</Text>
								</Tab>
								{ppmUser.isTeamApproverYN === "Y" && (
									<Tab textTransform="uppercase" fontWeight="semibold">
										<HStack align="center">
											<Text letterSpacing={1} display={{ base: "none", lg: "flex" }} px={2}>
												Pending Approval
											</Text>
											<Text letterSpacing={1} display={{ base: "flex", lg: "none" }} px={2}>
												Pending
											</Text>
											<Circle
												size="25px"
												bg="yellow.500"
												color="white"
												align="center"
												justify="center"
												opacity=".75"
											>
												{pendingCount}
											</Circle>
										</HStack>
									</Tab>
								)}
								<Tab
									textTransform="uppercase"
									fontWeight="semibold"
									maxW={{ base: "60px", md: "150px" }}
								>
									<Flex w="full" justify="flex-end">
										<Button
											as={Button}
											variant="outline"
											size="sm"
											ml={{ base: "", md: "0" }}
											pl={{ base: "", md: "0" }}
											color="gray.500"
											bg="whiteAlpha.700"
											shadow="sm"
										>
											<IconButton variant="unstyled" as={IoOptionsOutline} w={6} h={6} />
											<Text display={{ base: "none", md: "flex" }} ml="1" fontSize="md">
												FILTERS
											</Text>
										</Button>
									</Flex>
								</Tab>
							</TabList>

							<TabPanels>
								<TabPanel>
									{requests?.length === 0 && (
										<Center py="4">
											<Heading
												color="gray.400"
												p={2}
												size="md"
												letterSpacing={1}
												fontWeight={600}
											>
												N/A - No Upcoming Requests
											</Heading>
										</Center>
									)}
									<VStack divider={<StackDivider />} spacing={0}>
										{requests?.map(
											(row, i) =>
												i < 4 && (
													<TimeOffRequestTileRow
														key={i}
														viewOption={viewOption}
														openModal={openModal}
														row={row}
														holidays={holidays}
													/>
												)
										)}
									</VStack>
									<Collapse in={showMore}>
										<Divider />
										<VStack spacing={0} divider={<StackDivider />}>
											{requests?.map(
												(row, i) =>
													i >= 4 && (
														<TimeOffRequestTileRow
															key={i}
															viewOption={viewOption}
															openModal={openModal}
															row={row}
															holidays={holidays}
														/>
													)
											)}
										</VStack>
									</Collapse>
									{requests?.length > 4 && (
										<Center w="full">
											<Button
												w="full"
												size="sm"
												py="0"
												my="0"
												color="gray.500"
												variant="unstyled"
												_hover={{ textDecoration: "underline", color: "blue.600" }}
												onClick={toggleShowMore}
											>
												{!showMore ? "Show More" : "Show Less"}
											</Button>
										</Center>
									)}

									<Flex w="full" justify="center" pt="4">
										<Button
											variant="outline"
											onClick={openRequestModal}
											color="gray.500"
											px={2}
											size="md"
											bg="whiteAlpha.700"
											shadow="md"
											_hover={{
												color: "teal.500",
												fontWeight: "bold",
												bg: "whiteAlpha.700",
												borderColor: "teal.500",
												borderWidth: 2,
											}}
										>
											<HStack align="center" spacing={1}>
												<Text lineHeight={1} className="fas fa-plus fa-fw" fontSize="md" />
												<Text lineHeight={1} fontSize="md" letterSpacing={1} pr={1}>
													NEW REQUEST
												</Text>
											</HStack>
										</Button>
									</Flex>
								</TabPanel>

								{ppmUser.isTeamApproverYN === "Y" && (
									<TabPanel>
										{pendingCount === 0 && (
											<Center py="4">
												<Heading as="h5" size="md" color="gray.400">
													No Pending Requests
												</Heading>
											</Center>
										)}
										<VStack divider={<StackDivider />} spacing={0}>
											{pendingRequests?.map((row, i) => (
												<TimeOffRequestTileRow
													key={i}
													viewOption={"approver"}
													openModal={openModal}
													row={row}
													holidays={holidays}
												/>
											))}
										</VStack>
									</TabPanel>
								)}

								<TabPanel>
									<Box p={{ base: "1", md: "4" }}>
										<Stack w="full" spacing={4} divider={<StackDivider />}>
											<Stack spacing={1}>
												<Heading
													size="md"
													w="full"
													textAlign="left"
													letterSpacing={1}
													textTransform="uppercase"
													color="gray.500"
												>
													Request View Options
												</Heading>
												<Text as="i" color="gray.400">
													Who's time off requests would you like to see?
												</Text>
											</Stack>

											<SimpleGrid minChildWidth="10rem" spacing="4">
												<Flex
													justify="center"
													align="center"
													w="full"
													bg={viewOption === "employee" ? "whiteAlpha.700" : ""}
													shadow="sm"
													flex={1}
													rounded="5"
													p={{ base: "1", md: "4" }}
													border={viewOption === "employee" ? "2px" : "1px"}
													borderColor={
														viewOption === "employee" ? "teal.600" : "blackAlpha.200"
													}
													onClick={() => {
														setViewOptionFunction("employee");
													}}
													cursor="pointer"
													_hover={{ bg: "blackAlpha.50" }}
												>
													<VStack textAlign="center" w="full" spacing={1}>
														<Center
															color={viewOption === "employee" ? "teal.600" : "gray.400"}
														>
															<Heading>
																<FaUser />
															</Heading>
														</Center>
														<Text
															isTruncated
															color={viewOption === "employee" ? "teal.600" : "gray.400"}
															fontWeight="bold"
															fontSize="md"
															textTransform="uppercase"
														>
															My Time Off
														</Text>
														<Text as="i" fontSize="xs">
															Show My Time Off Requests Only
														</Text>
														<Center
															fontSize="4xl"
															color={viewOption === "employee" ? "teal.600" : "gray.300"}
														>
															{viewOption === "employee" ? (
																<MdRadioButtonChecked />
															) : (
																<MdRadioButtonUnchecked />
															)}
														</Center>
													</VStack>
												</Flex>

												<Flex
													justify="center"
													align="center"
													w="full"
													bg={viewOption === "department" ? "whiteAlpha.700" : ""}
													shadow="sm"
													flex={1}
													rounded="5"
													p={{ base: "1", md: "4" }}
													border={viewOption === "department" ? "2px" : "1px"}
													borderColor={
														viewOption === "department" ? "teal.600" : "blackAlpha.200"
													}
													onClick={() => {
														setViewOption("department");
													}}
													cursor="pointer"
													_hover={{ bg: "blackAlpha.50" }}
												>
													<VStack textAlign="center" w="full" spacing={1}>
														<Center
															color={
																viewOption === "department" ? "teal.600" : "gray.400"
															}
														>
															<Heading>
																<FaUsers />
															</Heading>
														</Center>
														<Text
															color={
																viewOption === "department" ? "teal.600" : "gray.400"
															}
															fontWeight="bold"
															fontSize="md"
															textTransform="uppercase"
														>
															My Department
														</Text>
														<Text as="i" fontSize="xs">
															Show Approved Time Off Requests for My Department
														</Text>
														<Center
															fontSize="4xl"
															color={
																viewOption === "department" ? "teal.600" : "gray.300"
															}
														>
															{viewOption === "department" ? (
																<MdRadioButtonChecked />
															) : (
																<MdRadioButtonUnchecked />
															)}
														</Center>
													</VStack>
												</Flex>
											</SimpleGrid>
										</Stack>

										<Stack w="full" spacing={4} divider={<StackDivider />} mt="8">
											<Stack spacing={1}>
												<Heading
													size="md"
													w="full"
													textAlign="left"
													letterSpacing={1}
													textTransform="uppercase"
													color="gray.500"
												>
													Date View Options
												</Heading>
												<Text as="i" color="gray.400">
													Which requests you like to see?
												</Text>
											</Stack>
											<SimpleGrid minChildWidth="10rem" spacing="4">
												<Flex
													w="full"
													bg={!viewAll ? "whiteAlpha.700" : ""}
													shadow="sm"
													flex={1}
													rounded="5"
													p="4"
													border={!viewAll ? "2px" : "1px"}
													borderColor={!viewAll ? "teal.600" : "blackAlpha.200"}
													onClick={viewAllFunction}
													cursor="pointer"
													justify="center"
													_hover={{ bg: "blackAlpha.50" }}
												>
													<VStack textAlign="center" w="full" spacing={1}>
														<Center color={!viewAll ? "teal.600" : "gray.400"}>
															<Heading>
																<VscArrowRight />
															</Heading>
														</Center>
														<Text
															color={!viewAll ? "teal.600" : "gray.400"}
															fontWeight="bold"
															fontSize="md"
															textTransform="uppercase"
														>
															Upcoming Requests
														</Text>
														<Text as="i" fontSize="sm">
															Only Show Upcoming Time Off Requests
														</Text>
														<Center
															fontSize="4xl"
															color={!viewAll ? "teal.600" : "gray.300"}
														>
															{!viewAll ? (
																<MdRadioButtonChecked />
															) : (
																<MdRadioButtonUnchecked />
															)}
														</Center>
													</VStack>
												</Flex>

												<Flex
													w="full"
													bg={viewAll ? "whiteAlpha.700" : ""}
													shadow="sm"
													flex={1}
													rounded="5"
													p="4"
													border={viewAll ? "2px" : "1px"}
													borderColor={viewAll ? "teal.600" : "blackAlpha.200"}
													onClick={viewAllFunction}
													cursor="pointer"
													justify="center"
													_hover={{ bg: "blackAlpha.50" }}
												>
													<VStack textAlign="center" w="full" spacing={1}>
														<Center color={viewAll ? "teal.600" : "gray.400"}>
															<Heading>
																<VscArrowSwap />
															</Heading>
														</Center>
														<Text
															color={viewAll ? "teal.600" : "gray.400"}
															fontWeight="bold"
															fontSize="md"
															textTransform="uppercase"
														>
															All Requests
														</Text>
														<Text as="i" fontSize="sm">
															Show Upcoming and Past Time Off Requests
														</Text>
														<Center
															fontSize="4xl"
															color={viewAll ? "teal.600" : "gray.300"}
															onClick={viewAllFunction}
														>
															{viewAll ? (
																<MdRadioButtonChecked />
															) : (
																<MdRadioButtonUnchecked />
															)}
														</Center>
													</VStack>
												</Flex>
											</SimpleGrid>

											<Flex w="full" justify="center" mt="4">
												<Button
													variant="outline"
													onClick={applyFilters}
													color="gray.500"
													ml={0}
													pl="0"
													size="sm"
													bg="whiteAlpha.700"
													shadow="sm"
												>
													<Center>
														<IconButton
															variant="unstyled"
															as={IoOptionsOutline}
															w={6}
															h={6}
														/>
														<Text ml="1" fontSize="md">
															{" "}
															APPLY FILTERS
														</Text>
													</Center>
												</Button>
											</Flex>
										</Stack>
									</Box>
								</TabPanel>
							</TabPanels>
						</Tabs>
					</Flex>
				</Skeleton>
			</Box>
		</BasicCard>
	);
}
