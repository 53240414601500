import React from "react";
// import { Redirect } from "react-router-dom";
import {
	Box,
	Flex,
	Table,
	Thead,
	Tbody,
	Tr,
	Td,
	Th,
	Button,
	Text,
	Heading,
	Skeleton,
	Container,
	Stack,
	HStack,
	VStack,
	StackDivider,
} from "@chakra-ui/react";
import MainContent from "../App/components/MainContent";
import ResourceGroupsPage from "./pages/ResourceGroupsPage";
import PayrollUploadPage from "./pages/PayrollUploadPage";
import PayPeriodSelect from "./components/PayPeriodSelect";
import ResourceGroupSelect from "./components/ResourceGroupSelect";
import TimecardHeaderCard from "./components/TimecardEntryCard";

import PayrollAPI from "../../APIs/PayrollAPI";
import ResourceGroupsObjClassLegacy from "./classes/ResourceGroupsObjClassLegacy";
import PayPeriodsObjClassLegacy from "./classes/PayPeriodsObjClassLegacy";
import TimecardJobClassLegacy from "./classes/TimecardJobClassLegacy";

import { portalUser, ppmUser } from "../../App";

export default class Timecard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			payrollAPI: new PayrollAPI(),
			tabVal: this.props.tabVal ?? 0,

			prEndDate: portalUser.props?.prEndDate,
			payPeriods: new PayPeriodsObjClassLegacy(),

			timecardJobs: null,

			resourceGroups: null,
			disabledGroups: [],
			activeGroup: null,
			employees: null,

			reviewJobs: null,
			reviewJobPhases: null,
			selectedJobKey: null,
		};

		this.init = this.init.bind(this);
		this.getTimecardJobs = this.getTimecardJobs.bind(this);
		this.getPayPeriods = this.getPayPeriods.bind(this);
		this.getResourceGroups = this.getResourceGroups.bind(this);
		this.getReviewJobs = this.getReviewJobs.bind(this);
		this.getReviewJobPhases = this.getReviewJobPhases.bind(this);

		this.changePayPeriod = this.changePayPeriod.bind(this);
		this.changeResourceGroup = this.changeResourceGroup.bind(this);

		this.changeTab = this.changeTab.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	componentDidUpdate() {
		if (this.props.prEndDate !== this.state.prEndDate) {
			this.setState(
				{
					prEndDate: this.props.prEndDate,
					resourceGroups: null,
					activeGroup: null,
					employees: null,

					reviewJobs: null,
					reviewJobPhases: null,
					selectedJobKey: null,
				},
				() => {
					this.init();
				}
			);
		}
	}

	async init() {
		let prEndDate = this.state.prEndDate;
		await this.getTimecardJobs();
		await this.getPayPeriods(prEndDate);
		await this.getResourceGroups(prEndDate);
		await this.getReviewJobs(prEndDate);
	}

	//AVAILABLE JOBS
	async getTimecardJobs(employeeUID = portalUser.user?.employeeUID) {
		//NOTE FOR REFACTORING - NEED TO SAVE EmployeeUID So that we can compare if the user is being impersonated!
		let timecardJobs = this.state.timecardJobs;
		if (!Boolean(timecardJobs?.length === 0)) {
			timecardJobs = await this.state.payrollAPI.GetTimecardJobs(employeeUID);
			if (timecardJobs.status === 200) {
				timecardJobs = timecardJobs.value;
				for (let i = 0; i < timecardJobs.length; i++) {
					timecardJobs[i] = new TimecardJobClassLegacy(timecardJobs[i]);
				}
				this.setState({
					timecardJobs: timecardJobs,
				});
			}
		}
	}

	//PAY PERIODS
	async getPayPeriods(prEndDate = portalUser.user?.prEndDate) {
		let payPeriods = this.state.payPeriods;
		if (payPeriods?.payPeriods?.length === 0) {
			payPeriods = await payPeriods.initPayPeriods(portalUser.user?.employeeUID, prEndDate);
		} else {
			payPeriods.setPayPeriod(prEndDate);
		}

		this.setState({
			payPeriods: payPeriods,
		});
	}

	//RESOURCE GROUPS
	async getResourceGroups(prEndDate = portalUser.user?.prEndDate) {
		let resourceGroups = this.state.resourceGroups;
		if (!Boolean(resourceGroups)) {
			resourceGroups = new ResourceGroupsObjClassLegacy(portalUser.user?.employeeUID, prEndDate);
			await resourceGroups.getAvailableResourceGroups();
			this.setState({
				resourceGroups: resourceGroups,
			});
		}

		let tabVal = this.state.tabVal;
		let level = null;
		if (tabVal === 1) {
			level = "crewEntry";
		} else {
			level = "approval";
		}

		let activeGroup = resourceGroups.getActiveGroup(level, null);

		this.setState(
			{
				resourceGroups: resourceGroups,
				activeGroup: activeGroup,
				employees: null,
			},
			async () => {
				if (resourceGroups?.availableResourceGroups?.length > 0) {
					await this.changeResourceGroup(level, null);
				} else {
					this.setState({
						activeGroup: 1,
					});
				}
			}
		);
	}

	//GET REVIEW JOBS
	async getReviewJobs(prEndDate = portalUser.user?.prEndDate) {
		let reviewJobs = this.state.reviewJobs;
		if (!Boolean(reviewJobs)) {
			reviewJobs = await this.state.payrollAPI.GetTimecardReviewJobs(portalUser.user?.employeeUID, prEndDate);
			if (reviewJobs.status === 200) {
				reviewJobs = reviewJobs.value;
			} else {
				reviewJobs = null;
			}
			this.setState({
				reviewJobs: reviewJobs,
			});
		}
	}

	//GET REVIEW JOBS
	async getReviewJobPhases(keyID = null, prEndDate = portalUser.user?.prEndDate) {
		let reviewJobPhases = null;
		if (!isNaN(keyID)) {
			reviewJobPhases = await this.state.payrollAPI.GetTimecardReviewJobPhases(keyID, prEndDate);
			if (reviewJobPhases.status === 200) {
				reviewJobPhases = reviewJobPhases.value;
			} else {
				keyID = null;
				reviewJobPhases = null;
			}
		} else {
			keyID = null;
			reviewJobPhases = null;
		}

		this.setState({
			selectedJobKey: keyID,
			reviewJobPhases: reviewJobPhases,
		});
	}

	changePayPeriod(payPeriod = null) {
		let prEndDate = payPeriod?.prEndDate ?? portalUser.user?.prEndDate;
		this.setState(
			{
				prEndDate: prEndDate,
				resourceGroups: null,
				activeGroup: null,
				employees: null,
				reviewJobs: null,
				selectedJobKey: null,
				reviewJobPhases: null,
			},
			() => {
				this.init();
			}
		);
	}

	async changeResourceGroup(level, activeGroup) {
		this.setState(
			{
				activeGroup: null,
			},
			async () => {
				let prEndDate = this.state.prEndDate;
				let resourceGroups = this.state.resourceGroups;
				let group = resourceGroups.getActiveGroup(level, activeGroup);

				let disabledGroups = [];
				let employees = this.state.employees ?? {};

				for (let i = 0; i < resourceGroups?.availableResourceGroups?.length; i++) {
					if (group?.groupUID === resourceGroups?.availableResourceGroups[i].groupUID) {
						disabledGroups.push(resourceGroups?.availableResourceGroups[i].groupName); // Passing groupName instead of id?
					} else if (level === "crewEntry" && !resourceGroups?.availableResourceGroups[i].crewEntry) {
						disabledGroups.push(resourceGroups?.availableResourceGroups[i].groupName);
					} else if (
						level === "approval" &&
						!resourceGroups?.availableResourceGroups[i].approval &&
						this.state.tabVal === 2
					) {
						disabledGroups.push(resourceGroups?.availableResourceGroups[i].groupName);
					}
				}

				if (Boolean(prEndDate) && !Boolean(group?.members)) {
					let loadedEmployees = await group?.getTimecardData(prEndDate);

					for (let i = 0; i < group?.members.length; i++) {
						if (employees[group.members[i]] === undefined) {
							employees[group.members[i]] = loadedEmployees[group.members[i]];
						}
					}
				}

				if (!Boolean(group)) {
					group = 1;
				}

				this.setState({
					resourceGroups: resourceGroups,
					activeGroup: group,
					disabledGroups: disabledGroups,
					employees: employees,
				});
			}
		);
	}

	async changeTab(tabVal) {
		this.setState(
			{
				tabVal: tabVal,
			},
			async () => {
				let level = null;
				if (tabVal === 1) {
					level = "crewEntry";
				} else if (tabVal === 2) {
					level = "approval";
				} else if (tabVal === 3) {
					level = "management";
				}
				let activeGroup = this.state.activeGroup;

				if (Boolean(activeGroup)) {
					await this.changeResourceGroup(level, activeGroup);
				}
			}
		);
	}

	render() {
		let tabs = ["My Time", "Crew Time", "Approve Time", "Resource Groups", "Job Review"];
		let disabledTabs = [];

		if (ppmUser.showTimecardApprovalYN === "N" && ppmUser.showTimecardCrewEntryYN === "N") {
			disabledTabs = [1, 2, 3];
		} else if (ppmUser.showTimecardApprovalYN === "Y" && ppmUser.showTimecardCrewEntryYN === "N") {
			disabledTabs = [1];
		} else if (ppmUser.showTimecardApprovalYN === "N" && ppmUser.showTimecardCrewEntryYN === "Y") {
			disabledTabs = [2];
		}

		let resourceGroupMembers = [];

		if (this.state.tabVal === 1 || this.state.tabVal === 2) {
			for (let i = 0; i < this.state.activeGroup?.members?.length; i++) {
				resourceGroupMembers.push(this.state.employees[this.state.activeGroup.members[i]]);
			}
		}

		return (
			<MainContent
				title={this.props.pageName}
				onTabChange={this.changeTab}
				tabVal={this.state.tabVal}
				tabs={tabs}
				disabledTabs={disabledTabs}
			>
				<Stack
					spacing={portalUser.styles?.pageSpacing}
					p={portalUser.styles?.pagePadding}
					bg={portalUser.styles?.pageBG}
					w="full"
					h="full"
					flex={1}
					align="center"
				>
					<Container maxW="8xl" w="full" display={"flex"} flex={1} px={0} flexDirection="column">
						{/* <Container maxW="8xl" px={getDeviceType() === "mobile" ? "0" : "4"}> */}
						<Stack
							spacing={4}
							direction={{ base: "column", sm: "row" }}
							justify={{ base: "flex-start", sm: "space-between" }}
						>
							{this.state.tabVal !== 3 && (
								<PayPeriodSelect
									tabVal={this.state.tabVal}
									payPeriods={this.state.payPeriods}
									changePayPeriod={this.changePayPeriod}
								/>
							)}

							{![0, 3, 4].includes(this.state.tabVal) && (
								<ResourceGroupSelect
									page={"timecard"}
									tabVal={this.state.tabVal}
									activeGroup={this.state.activeGroup}
									availableResourceGroups={this.state.resourceGroups?.availableResourceGroups}
									disabledGroups={this.state.disabledGroups}
									changeResourceGroup={this.changeResourceGroup}
								/>
							)}
						</Stack>

						{this.state.tabVal === 0 && (
							<Skeleton
								isLoaded={Boolean(this.state.payPeriods) && Boolean(this.state.timecardJobs)}
								rounded="md"
								minH="sm"
								py={2}
							>
								<TimecardHeaderCard
									member={ppmUser}
									prEndDate={this.state.prEndDate}
									payPeriods={this.state.payPeriods}
									timecardJobs={this.state.timecardJobs}
								/>
							</Skeleton>
						)}

						{(this.state.tabVal === 1 || this.state.tabVal === 2) && (
							<Skeleton
								isLoaded={
									Boolean(this.state.activeGroup) &&
									Boolean(this.state.payPeriods) &&
									Boolean(this.state.timecardJobs)
								}
								rounded="md"
								minH="sm"
							>
								<VStack w="full" spacing={2} p={{ base: "2", sm: "4" }} bg="blackAlpha.50" rounded="md">
									{Boolean(this.state.activeGroup) &&
										Boolean(this.state.payPeriods) &&
										Boolean(this.state.timecardJobs) && (
											<PayrollUploadPage
												payPeriods={this.state.payPeriods}
												resourceGroup={this.state.activeGroup}
												timecardJobs={this.state.timecardJobs}
												members={resourceGroupMembers}
											/>
										)}
								</VStack>
							</Skeleton>
						)}

						{this.state.tabVal === 3 &&
							(Boolean(this.state.activeGroup) || Boolean(ppmUser.isPortalAdmin)) && (
								<ResourceGroupsPage
									resourceGroup={this.state.activeGroup}
									timecardJobs={this.state.timecardJobs}
									members={resourceGroupMembers}
								/>
							)}

						{this.state.tabVal === 4 && (
							<Skeleton
								isLoaded={
									Boolean(this.state.activeGroup) &&
									Boolean(this.state.payPeriods) &&
									Boolean(this.state.timecardJobs) &&
									Boolean(this.state.reviewJobs)
								}
								rounded="md"
								minH="sm"
							>
								{Boolean(this.state.reviewJobs) &&
									Boolean(this.state.prEndDate) &&
									Boolean(this.state.payPeriods) &&
									Boolean(this.state.timecardJobs) && (
										<VStack w="full" id="jobReviewTable" spacing={10} divider={<StackDivider />}>
											<VStack
												w="full"
												spacing={2}
												p={{ base: "2", sm: "4" }}
												bg="blackAlpha.50"
												rounded="md"
											>
												{!Boolean(this.state.selectedJobKey) && (
													<Box maxW="7xl" w="full">
														<Heading
															py="2"
															w="full"
															textAlign="left"
															as="h5"
															size="md"
															color="gray.500"
															textTransform="uppercase"
														>
															Labor Hours By Job
														</Heading>
														<Box
															w="full"
															maxH="800px"
															overflowY="auto"
															className="skinnyScroll"
															pr="2"
														>
															<Table key={"reviewJobsTable"} size="sm" bg="white" pb="5">
																<Thead
																	textTransform="uppercase"
																	fontWeight="semibold"
																	bg="teal.600"
																	color="white"
																	top="0"
																	left="0"
																	zIndex="2"
																	position="sticky"
																>
																	<Tr>
																		<Th py="2" borderTopLeftRadius="5px">
																			<Text
																				fontSize="sm"
																				color="white"
																				textAlign="center"
																			>
																				Co
																			</Text>
																		</Th>
																		<Th>
																			<Text fontSize="sm" color="white">
																				Job
																			</Text>
																		</Th>
																		<Th>
																			<Text fontSize="sm" color="white">
																				Description
																			</Text>
																		</Th>
																		<Th textAlign="right">
																			<Text fontSize="sm" color="white">
																				REG
																			</Text>
																		</Th>
																		<Th textAlign="right">
																			<Text fontSize="sm" color="white">
																				OT
																			</Text>
																		</Th>
																		<Th textAlign="right">
																			<Text fontSize="sm" color="white">
																				DT
																			</Text>
																		</Th>
																		<Th
																			textAlign="right"
																			borderTopRightRadius="5px"
																		>
																			<Text fontSize="sm" color="white">
																				TOTAL
																			</Text>
																		</Th>
																	</Tr>
																</Thead>
																<Tbody>
																	{this.state.reviewJobs.map((job) => (
																		<Tr
																			onClick={() => {
																				this.getReviewJobPhases(
																					job.KeyID,
																					this.state.prEndDate
																				);
																			}}
																			key={job.KeyID}
																			className="hoverPointerLight"
																			fontWeight="semibold"
																			color="gray.500"
																		>
																			<Td py="3">
																				<Text fontSize="md" textAlign="center">
																					{job.JCCo}
																				</Text>
																			</Td>
																			<Td>
																				<Text fontSize="md">{job.Job}</Text>
																			</Td>
																			<Td>
																				<Text fontSize="md" isTruncated>
																					{job.JobDescription}
																				</Text>
																			</Td>
																			<Td textAlign="right">
																				<Text fontSize="md">
																					{job.REG.toFixed(1)}
																				</Text>
																			</Td>
																			<Td textAlign="right">
																				<Text fontSize="md">
																					{job.OT.toFixed(1)}
																				</Text>
																			</Td>
																			<Td textAlign="right">
																				<Text fontSize="md">
																					{job.DT.toFixed(1)}
																				</Text>
																			</Td>
																			<Td textAlign="right">
																				<Text fontSize="md">
																					{job.TOTAL.toFixed(1)}
																				</Text>
																			</Td>
																		</Tr>
																	))}
																</Tbody>
															</Table>
														</Box>
													</Box>
												)}
												{Boolean(this.state.selectedJobKey) && (
													<Box maxW="7xl" w="full">
														<Flex
															w="full"
															justify="space-between"
															align="center"
															py="2"
															pr="2"
														>
															<Heading
																w="full"
																textAlign="left"
																as="h5"
																size="md"
																color="gray.500"
																textTransform="uppercase"
															>
																Labor Hours By Phase
															</Heading>
															<Button
																variant="solid"
																border="2px"
																borderColor="gray.300"
																color="gray.500"
																px="2"
																size="sm"
																onClick={() => {
																	this.getReviewJobPhases(null, null);
																}}
															>
																<HStack>
																	<i className="fas fa-times-circle fa-fw"></i>
																	<Text textTransform="uppercase">CLOSE</Text>
																</HStack>
															</Button>
														</Flex>
														<Box
															w="full"
															maxH="800px"
															overflowY="auto"
															className="skinnyScroll"
															pr="2"
														>
															<Table
																key={"reviewJobsPhasesTable"}
																size="sm"
																bg="white"
																pb="5"
															>
																<Thead
																	textTransform="uppercase"
																	fontWeight="semibold"
																	bg="teal.600"
																	color="white"
																	top="0"
																	left="0"
																	zIndex="2"
																	position="sticky"
																>
																	<Tr>
																		<Th py="2" borderTopLeftRadius="5px">
																			<Text fontSize="sm" color="white">
																				Phase
																			</Text>
																		</Th>
																		<Th>
																			<Text fontSize="sm" color="white">
																				Description
																			</Text>
																		</Th>
																		<Th>
																			<Text fontSize="sm" color="white">
																				Craft
																			</Text>
																		</Th>
																		<Th>
																			<Text fontSize="sm" color="white">
																				Employee
																			</Text>
																		</Th>
																		<Th>
																			<Text fontSize="sm" color="white">
																				Title
																			</Text>
																		</Th>
																		<Th textAlign="right">
																			<Text fontSize="sm" color="white">
																				REG
																			</Text>
																		</Th>
																		<Th textAlign="right">
																			<Text fontSize="sm" color="white">
																				OT
																			</Text>
																		</Th>
																		<Th textAlign="right">
																			<Text fontSize="sm" color="white">
																				DT
																			</Text>
																		</Th>
																		<Th
																			textAlign="right"
																			borderTopRightRadius="5px"
																		>
																			<Text fontSize="sm" color="white">
																				TOTAL
																			</Text>
																		</Th>
																	</Tr>
																</Thead>
																<Tbody>
																	{this.state.reviewJobPhases?.map((phases, i) => (
																		<Tr
																			key={i}
																			className="hoverPointerLight"
																			fontWeight="semibold"
																			color="gray.500"
																		>
																			<Td py="3">
																				<Text fontSize="md">
																					{phases.Phase}
																				</Text>
																			</Td>
																			<Td>
																				<Text fontSize="md" isTruncated>
																					{phases.PhaseDescription}
																				</Text>
																			</Td>
																			<Td>
																				<Text fontSize="md">
																					{phases.Craft}
																				</Text>
																			</Td>
																			<Td>
																				<Text fontSize="md">
																					{phases.EmployeeName}
																				</Text>
																			</Td>
																			<Td>
																				<Text fontSize="md">
																					{phases.Title}
																				</Text>
																			</Td>
																			<Td textAlign="right">
																				<Text fontSize="md">
																					{phases.REG.toFixed(1)}
																				</Text>
																			</Td>
																			<Td textAlign="right">
																				<Text fontSize="md">
																					{phases.OT.toFixed(1)}
																				</Text>
																			</Td>
																			<Td textAlign="right">
																				<Text fontSize="md">
																					{phases.DT.toFixed(1)}
																				</Text>
																			</Td>
																			<Td textAlign="right">
																				<Text fontSize="md">
																					{phases.TOTAL.toFixed(1)}
																				</Text>
																			</Td>
																		</Tr>
																	))}
																</Tbody>
															</Table>
														</Box>
													</Box>
												)}
											</VStack>
										</VStack>
									)}
							</Skeleton>
						)}
					</Container>
				</Stack>
			</MainContent>
		);
	}
}
