import React, { useState, useMemo, useEffect } from "react";
import {
	Box,
	Center,
	Flex,
	Stack,
	Heading,
	Button,
	HStack,
	Text,
	Skeleton,
	useBoolean,
	useToast,
} from "@chakra-ui/react";
import Modal from "../../../core/Layout/modals/Modal";
import DataInput from "../../../core/Inputs/data/DataInput";
import UsersAPI from "../../../APIs/UsersAPI";

import { portalUser } from "../../../App";
import { dayDifference, formatValue } from "../../../helperFunctions";
import moment from "moment";

export default function TimeOffAcknowledgeModal(props) {
	const usersAPI = new UsersAPI();
	const toast = useToast();
	const [editable, toggleEdit] = useBoolean();
	const [startDate, setStartDate] = useState(new Date(props.request?.startDate ?? null));
	const [endDate, setEndDate] = useState(new Date(props.request?.endDate ?? null));
	const [holidays, setHolidays] = useState([]);
	const [calculating, setCalculating] = useState(false);
	const [requestNote, setRequestNote] = useState(props.request?.requestNote);
	const [type, setType] = useState(props.request?.type);
	const [invalid, setInvalid] = useState(false);
	const [holidayCount, setHolidayCount] = useState(0);
	const [totalHours, setTotalHours] = useState(0);

	const updateStartDate = async (date, name) => {
		let tempDate = endDate;
		if (name === "start") {
			if (date.getTime() > endDate.getTime()) {
				tempDate = new Date(date);
				tempDate.setDate(date.getDate());
				setEndDate(tempDate);
			}
			date = new Date(date);
			date.setHours(0);
			setStartDate(date);
		} else if (name === "end") {
			date = new Date(date);
			date.setHours(5);
			setEndDate(date);
		}
		setCalculating(true);

		let params = {};
		let query = {
			eventType: "Holiday",
			startDate: new Date(name === "start" ? date : startDate).toLocaleDateString(),
			endDate: new Date(name === "start" ? tempDate : date).toLocaleDateString(),
		};
		let holidays = await usersAPI.GetCalendarEvents(params, query);

		setHolidays(holidays.value);
		setCalculating(false);
	};

	useEffect(() => {
		const fetchHolidays = async () => {
			try {
				let params = {};
				let query = {
					eventType: "Holiday",
					startDate: new Date(startDate).toLocaleDateString(),
					endDate: new Date(endDate).toLocaleDateString(),
				};

				const response = await usersAPI.GetCalendarEvents(params, query);
				setHolidays(response.value);
			} catch (error) {
				console.error("Error fetching holidays:", error);
			}
		};

		fetchHolidays();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endDate, startDate]);

	useMemo(() => {
		let tempHolidayCount = 0;
		for (let i = 0; i < holidays?.length; i++) {
			let startDate = new Date(formatValue(holidays[i]?.StartDate, 0, "date"));
			let endDate = new Date(formatValue(holidays[i]?.EndDate, 0, "date"));
			endDate.setDate(endDate.getDate() + 1);
			while (
				moment(startDate)?.startOf("date")?.format("mm/dd/yyyy") !==
				moment(endDate)?.startOf("date")?.format("mm/dd/yyyy")
			) {
				if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
					tempHolidayCount++;
				}
				startDate.setDate(startDate.getDate() + 1);
			}
		}
		setHolidayCount(tempHolidayCount);
		let totalHours = 8 * (dayDifference(startDate, endDate) - holidayCount);
		if (totalHours < 0) {
			totalHours = 0;
		}
		setTotalHours(totalHours);
	}, [endDate, holidayCount, holidays, startDate]);

	const changeType = (ev) => {
		setType(ev.target.value);
	};

	const validateForm = () => {
		if (type === null || startDate === null || endDate === null) {
			setInvalid(true);
			return false;
		} else {
			setInvalid(false);
			return true;
		}
	};

	const updateRequestNote = (ev) => {
		let note = ev.target.value;
		setRequestNote(note);
	};

	const saveChanges = () => {
		let formComplete = validateForm();
		if (!formComplete) {
			return false;
		}
		let changes = {
			startDate: startDate,
			endDate: endDate,
			requestNote: requestNote,
			type: type,
		};
		props.updateRequest(changes);
	};

	let availableHours = 0;
	if (props.request?.type?.toLowerCase() === "vacation") {
		availableHours = props.employee?.availableVacation ?? 0;
	} else if (props.request?.type?.toLowerCase() === "sick") {
		availableHours = props.employee?.availableSick ?? 0;
	}

	let requestedHours = props.request?.requestedHours - holidayCount * 8;
	let statusIcon =
		availableHours.toFixed(0) >= requestedHours ? "fas fa-check-circle fa-fw " : "fas fa-times-circle fa-fw ";

	let approvalIcon = props.request.getStatusInfo();

	let footer = (
		<Center direction="row" w="full" p={4}>
			{props.request?.sentTo?.toLowerCase() === portalUser?.user?.employeeUID?.toLowerCase() && (
				<HStack spacing="4" w="full">
					<Button
						variant="outline"
						colorScheme="red"
						flex={1}
						onClick={() => {
							props.decline();
							props.onClose();
							toast({
								title: "Denied!",
								description: "The timeoff request has been denied.",
								status: "error",
								duration: 5000,
								isClosable: true,
							});
						}}
					>
						DENY REQUEST
					</Button>
					<Button
						disabled={approvalIcon?.status === "approved"}
						variant="solid"
						colorScheme="green"
						flex={1}
						onClick={() => {
							props.approve();
							props.onClose();
							toast({
								title: "Approved!",
								description: "The timeoff request has been approved.",
								status: "success",
								duration: 5000,
								isClosable: true,
							});
						}}
					>
						{approvalIcon?.status === "approved" ? "REQUEST APPROVED" : "APPROVE REQUEST"}
					</Button>
				</HStack>
			)}
			{props.request?.employeeUID?.toLowerCase() === portalUser?.user?.employeeUID?.toLowerCase() && (
				<HStack spacing="4" w="full">
					<Button variant="outline" colorScheme="red" flex={1} onClick={props.cancel}>
						CANCEL REQUEST
					</Button>
					<Button variant="solid" colorScheme="blue" flex={1} onClick={toggleEdit.toggle} hidden={editable}>
						EDIT REQUEST
					</Button>
					<Button variant="solid" colorScheme="green" flex={1} onClick={saveChanges} hidden={!editable}>
						SAVE CHANGES
					</Button>
				</HStack>
			)}
		</Center>
	);

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={props.onClose}
			color="gray.500"
			title={"Time Off Request"}
			size="lg"
			footer={footer}
		>
			<Box>
				<Skeleton isLoaded={props.request} rounded="5">
					<Flex direction="column" flex={1}>
						<Flex flex={1} w="full" mb="2">
							<Stack flex={1} w="full" spacing={1} textAlign="left">
								<Heading
									as="h4"
									mb="1"
									textTransform="uppercase"
									py="1"
									isTruncated
									letterSpacing={1}
									fontWeight="semibold"
									size="xs"
									color="gray.400"
								>
									{props.request?.getDays() === 1 ? "Requested Date" : "Requested Dates"}
								</Heading>
								{props.request?.getDays() === 1 && (
									<Heading
										isTruncated
										as="h4"
										letterSpacing={1}
										fontWeight="semibold"
										size="sm"
										color="gray.500"
									>
										{moment(props.request.startDate).format("MMM ").toUpperCase() +
											moment(props.request.startDate).format("Do") +
											(requestedHours / 8 === 1
												? moment(props.request.startDate).format(" YYYY")
												: "")}{" "}
									</Heading>
								)}
								{props.request?.getDays() !== 1 && (
									<Heading
										isTruncated
										as="h4"
										letterSpacing={1}
										fontWeight="semibold"
										size="sm"
										color="gray.500"
									>
										{moment(props.request.startDate).format("MMM ").toUpperCase() +
											moment(props.request.startDate).format("Do") +
											" to " +
											moment(props.request.endDate).format("MMM ").toUpperCase() +
											moment(props.request.endDate).format("Do ") +
											moment(props.request.endDate).format("YYYY")}{" "}
									</Heading>
								)}

								<Text letterSpacing={1} fontSize="sm" color="gray.500">
									{props.request?.requestedHours / 8 +
										" " +
										props.request?.type +
										" Day" +
										(props.request?.requestedHours / 8 > 1 ? "s (" : " (") +
										props.request?.requestedHours +
										" Hours)"}{" "}
								</Text>
								<HStack
									w="full"
									spacing={1}
									align="center"
									justify="flex-start"
									key={statusIcon}
									color={availableHours.toFixed(0) >= requestedHours ? "green.600" : "red.600"}
								>
									<i className={statusIcon} />
									<Text fontWeight="bold" letterSpacing={1} fontSize="sm">
										{availableHours.toFixed(0) + " Hours Available"}
									</Text>
								</HStack>
							</Stack>

							<Stack flex={1} w="full" spacing={2} textAlign="right">
								<Heading
									as="h4"
									mb="1"
									textTransform="uppercase"
									py="1"
									isTruncated
									letterSpacing={1}
									fontWeight="semibold"
									size="xs"
									color="gray.400"
								>
									Request From
								</Heading>
								<Heading
									as="h4"
									textTransform="uppercase"
									letterSpacing={1}
									fontWeight="bold"
									size="sm"
									color="gray.500"
								>
									{props.request.name}
								</Heading>
								<HStack w="full" spacing={1} align="center" textAlign="right" justify="flex-end">
									<Heading
										as="h4"
										textTransform="uppercase"
										letterSpacing={2}
										fontWeight="bold"
										size="xs"
										color="gray.400"
									>
										{"to " + props.request.sentToName}
									</Heading>
								</HStack>
								<HStack
									w="full"
									spacing={1}
									align="center"
									color={approvalIcon?.color ?? "yellow.500"}
									textAlign="right"
									justify="flex-end"
									key={approvalIcon?.icon ?? "n/a"}
								>
									<i className={(approvalIcon?.icon ?? "fas fa-question-circle") + " fa-fw"} />
									<Text textTransform="uppercase" fontWeight="bold" letterSpacing={2} fontSize="sm">
										{approvalIcon?.status ?? "Pending"}
									</Text>
								</HStack>
							</Stack>
						</Flex>

						{!editable && (
							<Stack flex={1} w="full" spacing={3}>
								<Stack flex={1} w="full" spacing={1}>
									<Heading
										as="h4"
										textTransform="uppercase"
										py="1"
										isTruncated
										letterSpacing={1}
										fontWeight="semibold"
										size="xs"
										color="gray.400"
									>
										Employee Notes
									</Heading>
									<Text letterSpacing={1} fontSize="sm" color="gray.500">
										{props.request?.requestNote ?? "N/A"}
									</Text>
								</Stack>

								<Stack flex={1} w="full" spacing={1}>
									<Heading
										as="h4"
										textTransform="uppercase"
										py="1"
										isTruncated
										letterSpacing={1}
										fontWeight="semibold"
										size="xs"
										color="gray.400"
									>
										{portalUser.user.managerUID?.toLowerCase() ===
										props.request?.sentTo?.toLowerCase()
											? "Manager Notes"
											: "Approver Notes"}
									</Heading>
									{props.request?.sentTo?.toLowerCase() !==
										portalUser?.user?.employeeUID?.toLowerCase() && (
										<Text letterSpacing={1} fontSize="sm" color="gray.500">
											{props.request?.note ?? "N/A"}
										</Text>
									)}
									{props.request?.sentTo?.toLowerCase() ===
										portalUser?.user?.employeeUID?.toLowerCase() && (
										<DataInput
											type="textarea"
											rowsMin={2}
											placeholder="Add notes here..."
											onBlur={props.updateNote}
											defaultValue={props.request?.note}
											isModal
										/>
									)}
								</Stack>
							</Stack>
						)}

						{editable && (
							<>
								<Heading
									as="h4"
									mb="1"
									mt="4"
									textTransform="uppercase"
									py="1"
									isTruncated
									letterSpacing={1}
									fontWeight="semibold"
									size="xs"
									color="gray.400"
								>
									Edit Request Info
								</Heading>
								<Text as="i" fontSize="sm" color="gray.400">
									*Editing the requested date(s) will reset the status to PENDING
								</Text>
								<Stack
									w="full"
									h="full"
									flex={1}
									bg="blackAlpha.50"
									p="4"
									rounded="5"
									border="1px"
									borderColor="blackAlpha.100"
								>
									<HStack w="full" h="full" spacing="3" flex={1}>
										<Flex flex={1} w="full">
											<DataInput
												inputBG="white"
												width="100%"
												label="Start Date"
												name="start"
												onChange={updateStartDate}
												value={startDate}
												type="date"
												minDate={new Date()}
												isInvalid={invalid ? startDate === null : false}
												isRequired={invalid ? startDate === null : false}
												isModal
											/>
										</Flex>
										<Flex flex={1} w="full">
											<DataInput
												inputBG="white"
												w="100%"
												label="End Date"
												name="end"
												onChange={updateStartDate}
												value={endDate}
												type="date"
												minDate={startDate}
												isInvalid={invalid ? endDate === null : false}
												isRequired={invalid ? endDate === null : false}
												isModal
											/>
										</Flex>
									</HStack>

									{!calculating && (
										<Text
											display={holidayCount > 0 ? "flex" : "none"}
											as="i"
											fontSize="xs"
											letterSpacing={1}
											color="gray.400"
											w="full"
										>
											{"*There " +
												(holidayCount > 1 ? "are " : "is ") +
												holidayCount +
												(holidayCount > 1 ? " holidays" : " holiday") +
												" within the specified date range."}
										</Text>
									)}

									<Flex flex={1} w="full" direction="column" h="full">
										<DataInput
											inputBG="white"
											colorScheme="teal"
											label="Request Type"
											name="type"
											onChange={changeType}
											options={[{ type: "Vacation" }, { type: "Sick" }]}
											value={type}
											type="select"
											optionText="type"
											optionValue="type"
											defaultValue={type}
											required
											isInvalid={invalid ? type === null : false}
											isRequired={invalid ? type === null : false}
											variant="outline"
											isModal
										/>
									</Flex>
									<Flex flex={1} w="full">
										<Stack flex={1} w="full" spacing={1}>
											<Heading
												as="h4"
												textTransform="uppercase"
												isTruncated
												fontWeight="semibold"
												size="sm"
												color="gray.500"
											>
												Notes
											</Heading>
											<DataInput
												type="textarea"
												rowsMin={2}
												placeholder="Add Notes (optional)"
												onBlur={updateRequestNote}
												defaultValue={requestNote}
												isModal
											/>
										</Stack>
									</Flex>
								</Stack>

								<Stack spacing="0" w="full" mb="4" mt="2">
									<Text
										as="i"
										fontSize="xs"
										letterSpacing={1}
										color="gray.400"
										w="full"
										textAlign="right"
									>
										{dayDifference(startDate, endDate) +
											" Work Day" +
											(dayDifference(startDate, endDate) > 1 ? "s" : "") +
											(holidayCount > 0
												? " - " + holidayCount + " Holiday" + (holidayCount > 1 ? "s" : "")
												: "") +
											" = " +
											totalHours +
											" " +
											(type === null ? "Vacation" : type) +
											" Hours"}
									</Text>
								</Stack>
							</>
						)}
					</Flex>
				</Skeleton>
			</Box>
		</Modal>
	);
}
