import React, { useState, useEffect } from "react";
import {
	Box,
	Center,
	Flex,
	Stack,
	Heading,
	Button,
	HStack,
	Text,
	Tag,
	TagLabel,
	TagCloseButton,
} from "@chakra-ui/react";
import moment from "moment";
import Modal from "../../../core/Layout/modals/Modal";
import UsersAPI from "../../../APIs/UsersAPI";
import { portalUser } from "../../../App";
import { dayDifference, formatDate } from "../../../helperFunctions";
import DataInput from "../../../core/Inputs/data/DataInput";

export default function TimeOffRequestModal(props) {
	const [type, setType] = useState(null);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const [managerUID, setManagerUID] = useState(props.managerUID?.toLowerCase() ?? null);
	const [secondaryManagerUid, setSecondaryManagerUid] = useState(null);
	const [invalid, setInvalid] = useState(false);
	const [notifyUsers, setNotifyUsers] = useState([]);
	const [holidays, setHolidays] = useState([]);
	const [calculating, setCalculating] = useState(false);
	const [requestNote, setRequestNote] = useState(null);
	const [buttonDisabled, setButtonDisabled] = useState(false);

	// const [hoursPerDay, setHoursPerDay] = useState(8);
	const hoursPerDay = 8;

	const changeType = (ev) => {
		setType(ev.target.value);
	};

	const updateStartDate = async (date, name) => {
		let tempDate = endDate;
		if (name === "start") {
			if (date.getTime() > endDate.getTime()) {
				tempDate = new Date(date);
				tempDate.setDate(date.getDate());
				setEndDate(tempDate);
			}
			date = new Date(date);
			date.setHours(0);
			setStartDate(date);
		} else if (name === "end") {
			date = new Date(date);
			date.setHours(5);
			setEndDate(date);
		}
		setCalculating(true);
		let usersAPI = new UsersAPI();
		let params = {};
		let query = {
			eventType: "Holiday",
			startDate: new Date(name === "start" ? date : startDate).toLocaleDateString(),
			endDate: new Date(name === "start" ? tempDate : date).toLocaleDateString(),
		};
		let holidays = await usersAPI.GetCalendarEvents(params, query);
		//portalUser.getCalendarEvents()?.filter((d)=> d.eventType === 'Holiday')

		// log(name);
		// log(holidays);
		setHolidays(holidays.value);
		setCalculating(false);
	};

	// const changeHours = (ev) => {
	// 	let hours = ev.target.value;
	// 	setHoursPerDay(hours);
	// };

	const changeManager = (uid) => {
		setManagerUID(uid?.toLowerCase());
	};

	const changeSecondaryManager = (secondaryUid) => {
		if (secondaryUid === "N/A") {
			secondaryUid = null;
		} else {
			let user = props.users.find(
				({ employeeUID }) => employeeUID?.toLowerCase() === secondaryUid?.toLowerCase()
			);
			let users = [...notifyUsers];
			users.push(user);
			setNotifyUsers(users);
		}
		secondaryUid = null;
		setSecondaryManagerUid(secondaryUid?.toLowerCase());
	};

	const removeUser = (uid) => {
		let users = [...notifyUsers];
		for (let i = 0; i < users.length; i++) {
			if (users[i].employeeUID?.toLowerCase() === uid?.toLowerCase()) {
				users.splice(i, 1);
				break;
			}
		}
		setNotifyUsers(users);
	};

	const updateRequestNote = (ev) => {
		let note = ev.target.value;
		setRequestNote(note);
	};

	const validateForm = () => {
		if (type === null) {
			setType("Vacation");
		}
		if (managerUID === null || startDate === null || endDate === null) {
			setInvalid(true);
			return false;
		} else {
			setInvalid(false);
			return true;
		}
	};

	const submitRequest = () => {
		let formComplete = validateForm();
		if (!formComplete) {
			return false;
		}

		let requestedHours = hoursPerDay * dayDifference(startDate, endDate);
		let request = {
			type: type,
			startDate: startDate?.toLocaleDateString(),
			endDate: endDate?.toLocaleDateString(),
			requestedHours: requestedHours,
			sentTo: managerUID?.toLowerCase(),
			secondarySentTo: secondaryManagerUid?.toLowerCase(),
			notifyUsers: notifyUsers,
			requestNote: requestNote,
		};
		props.createRequest(request);
		props.closeModal();
	};

	let hourOptions = [];
	for (let i = 0; i < 9; i++) {
		hourOptions.push({ hours: i });
	}

	const typeOptions = [{ type: "Vacation" }, { type: "Sick" }];

	let footer = (
		<Center direction="row" w="full" p={4}>
			<HStack spacing="4" w="full">
				<Button variant="outline" colorScheme="red" flex={1} onClick={props.closeModal}>
					CANCEL REQUEST
				</Button>
				<Button
					variant="solid"
					colorScheme="green"
					flex={1}
					onClick={() => {
						submitRequest();
					}}
					disabled={buttonDisabled}
				>
					SUBMIT REQUEST
				</Button>
			</HStack>
		</Center>
	);

	let holidayCount = 0;
	for (let i = 0; i < holidays?.length; i++) {
		let startDate = new Date(formatDate(holidays[i]?.StartDate));
		let endDate = new Date(formatDate(holidays[i]?.EndDate));
		endDate.setDate(endDate.getDate() + 1);
		while (
			moment(startDate)?.startOf("date")?.format("mm/dd/yyyy") !==
			moment(endDate)?.startOf("date")?.format("mm/dd/yyyy")
		) {
			if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
				holidayCount++;
			}
			startDate.setDate(startDate.getDate() + 1);
		}
	}

	let totalHours = 8 * (dayDifference(startDate, endDate) - holidayCount);
	if (totalHours < 0) {
		totalHours = 0;
	}

	const textareaStyle = {
		width: "100%",
		color: "#4A5568",
		textAlign: "left",
		paddingLeft: "5px",
		paddingRight: "5px",
		paddingTop: "2px",
		paddingBottom: "2px",
		borderRadius: "5px",
		border: "1px solid #E2E8F0",
	};

	// const lowercaseManagers = props.managers.map((obj) => ({
	// 	...obj,
	// 	employeeUID: obj["employeeUID"]?.toLowerCase(),
	// }));

	useEffect(() => {
		if (
			!Boolean(managerUID || portalUser.user.approverUID) ||
			managerUID.length === 0 ||
			portalUser.user.approverUID.length === 0 ||
			managerUID === "n/a"
		) {
			setButtonDisabled(true);
		} else {
			setButtonDisabled(false);
		}
	}, [managerUID]);

	return (
		<Modal
			title="Time Off Request"
			isOpen={props.isOpen}
			onClose={props.closeModal}
			size="lg"
			onSubmit={submitRequest}
			submitting={props.submitting}
			footer={footer}
		>
			<Box>
				<Heading
					as="h4"
					mb="1"
					textTransform="uppercase"
					py="1"
					isTruncated
					letterSpacing={1}
					fontWeight="semibold"
					size="xs"
					color="gray.400"
				>
					Request Info
				</Heading>

				<Stack
					w="full"
					h="full"
					flex={1}
					bg="blackAlpha.50"
					p="4"
					rounded="5"
					border="1px"
					borderColor="blackAlpha.100"
				>
					<HStack w="full" h="full" flex={1}>
						<Flex flex={1} w="full">
							<DataInput
								inputBG="white"
								width="100%"
								label="Start Date"
								name="start"
								onChange={updateStartDate}
								value={startDate}
								type="date"
								minDate={new Date()}
								isInvalid={invalid ? startDate === null : false}
								isRequired={invalid ? startDate === null : false}
								isModal
							/>
						</Flex>
						<Flex flex={1}></Flex>
						<Flex flex={1} w="full">
							<DataInput
								inputBG="white"
								w="100%"
								label="End Date"
								name="end"
								onChange={updateStartDate}
								value={endDate}
								type="date"
								minDate={startDate}
								isInvalid={invalid ? endDate === null : false}
								isRequired={invalid ? endDate === null : false}
								isModal
							/>
						</Flex>
					</HStack>
					{!calculating && (
						<Text
							display={holidayCount > 0 ? "flex" : "none"}
							as="i"
							fontSize="xs"
							letterSpacing={1}
							color="gray.400"
							w="full"
						>
							{"*There " +
								(holidayCount > 1 ? "are " : "is ") +
								holidayCount +
								(holidayCount > 1 ? " holidays" : " holiday") +
								" within the specified date range."}
						</Text>
					)}
					<Flex flex={1} w="full" direction="column" h="full">
						<DataInput
							inputBG="white"
							colorScheme="teal"
							label="Request Type"
							name="type"
							onChange={changeType}
							options={typeOptions}
							value={type}
							type="select"
							optionText="type"
							optionValue="type"
							defaultValue={type}
							required
							isInvalid={invalid ? type === null : false}
							isRequired={invalid ? type === null : false}
							variant="outline"
							isModal
						/>
					</Flex>
					<Flex flex={1} w="full">
						<Stack flex={1} w="full" spacing={1}>
							<Heading
								as="h4"
								textTransform="uppercase"
								isTruncated
								fontWeight="semibold"
								size="sm"
								color="gray.500"
							>
								Notes
							</Heading>
							<DataInput
								type="textarea"
								rowsMin={2}
								mt="-1"
								style={textareaStyle}
								placeholder="Add Notes (optional)"
								onBlur={updateRequestNote}
								defaultValue={requestNote}
								isModal
							/>
						</Stack>
					</Flex>
				</Stack>

				<Stack spacing="0" w="full" mb="4" mt="2">
					<Text as="i" fontSize="xs" letterSpacing={1} color="gray.400" w="full" textAlign="right">
						{dayDifference(startDate, endDate) +
							" Work Day" +
							(dayDifference(startDate, endDate) > 1 ? "s" : "") +
							(holidayCount > 0
								? " - " + holidayCount + " Holiday" + (holidayCount > 1 ? "s" : "")
								: "") +
							" = " +
							totalHours +
							" " +
							(type === null ? "Vacation" : type) +
							" Hours"}
					</Text>
				</Stack>

				<Heading
					as="h4"
					mb="1"
					textTransform="uppercase"
					py="1"
					isTruncated
					letterSpacing={1}
					fontWeight="semibold"
					size="xs"
					color="gray.400"
				>
					Notifications
				</Heading>
				<Stack
					w="full"
					h="full"
					flex={1}
					bg="blackAlpha.50"
					px="4"
					py="2"
					rounded="5"
					border="1px"
					borderColor="blackAlpha.100"
				>
					{managerUID === "n/a" && (
						<Flex alignItems="center">
							<i class="fas fa-exclamation-circle" style={{ color: "red" }}></i>
							<Text color="red" pl={1}>
								Approver not assigned. Please contact David Gebert.
							</Text>
						</Flex>
					)}
					<DataInput
						inputBG="white"
						label="Approved By"
						name="manager"
						type="select"
						onOptionSelect={changeManager}
						options={props.managers}
						optionText="name"
						searchable
						optionValue="employeeUID"
						value={managerUID?.toLowerCase() ?? ""}
						includeNA
						isInvalid={invalid ? managerUID === null : false}
						isRequired={invalid ? managerUID === null : false}
						optionSubheading="email"
						isModal
					/>

					<DataInput
						inputBG="white"
						label="Also Notify"
						name="secondaryManager"
						type="select"
						onOptionSelect={changeSecondaryManager}
						options={props.users}
						optionText="name"
						searchable
						optionValue="employeeUID"
						value={secondaryManagerUid ?? "N/A"}
						includeNA
						selectedOptions={notifyUsers}
						multiple
						optionSubheading="email"
						placeholder="Search Employees..."
						isModal
					/>
					<Stack direction="row" wrap="wrap" w="full" shouldWrapChildren>
						{notifyUsers.map((user, i) => (
							<Tag k size="sm" key={i} variant="solid" colorScheme="teal" my={1} mr="2">
								<TagLabel>{user.firstName + " " + user.lastName}</TagLabel>
								<TagCloseButton
									onClick={() => {
										removeUser(user?.employeeUID);
									}}
								/>
							</Tag>
						))}
					</Stack>
				</Stack>
				<Text as="i" fontSize="xs" color="gray.400" mt="-1">
					*The approver and others will be notified automatically
				</Text>
			</Box>
		</Modal>
	);
}
